import React, { useEffect } from "react";
import { Link } from "gatsby";

import PageLayout from "../../components/page-layout";

import ukraineImage from "../../images/ukraine.png";

import * as style from "../index.module.css";
import * as specialStyle from "../about.module.css";

const AboutPage = () => {
  return (
    <PageLayout
      title="Call A Colleague"
      description="Peer to Peer Career Mentoring for professionals forced to flee"
      image={ukraineImage}
    >
      <section className={specialStyle.section}>
        <img src={ukraineImage} width="64" height="42" />
        <h1>Call A Colleague</h1>

        <h2>
          Call A Colleague is a free peer to peer career consulting platform for
          refugees of Russian war in Ukraine
        </h2>

        <p>
          People forced to flee from the Russian war can easily find a colleague
          in a European country and immediately book this person for a{" "}
          <b>free 20 minutes video call to ask their job hunting questions.</b>
        </p>
        <p>
          The platform was founded by a Berliner couple <b>Olga Sokolova</b>{" "}
          (35, diverse Slavic roots, Cultural Scientist, International Career
          Consultant) and <b>Elshad Shirinov</b> (38, Ukrainian Jew from
          Charkow, Software Architect, CTO).
        </p>
        <h3>Why now?</h3>
        <p>
          Looking for employment in another country is a challenge even in times
          of peace. Millions of Ukranians, thousands of Russians and Belorussins
          were forced to flee from Putin's criminal war. The loss of roots and
          helplessness that people feel now can hardly be described.
        </p>
        <p>
          Job and career is probably the one sphere of human life we have most
          control over. Career is also one of the best ways to overcome the
          trauma of control loss.
        </p>
        <p>
          150 engaged professionals from the EU and the world have already
          registered as career mentors for their colleagues from Ukraine. Among
          them engineers, project & product managers, marketing pros, teachers,
          software developers, data people, designers…
        </p>
        <p>
          <b>Over 1000</b> people already got consultations via a
          call-a-colleague.
        </p>
        <h3>How does it work?</h3>
        <p>
          A call (video or audio) happens via service{" "}
          <a href="https://meet.jit.si/" target="_blank">
            Jitsi
          </a>
          . Both sides get the same link - so that only they can participate in
          the conversation that is encrypted. You only need a smartphone or a
          laptop.
        </p>
        <p>
          Mentors can cancel their engagement any time. Mentors define the time
          and the amount of contacts on their own.
        </p>
        <p>
          All professionals currently employed in the EU can register as mentors
          following the link
        </p>
        <p>
          <a href="https://call-a-colleague.eu/mentors" target="_blank">
            https://call-a-colleague.eu/mentors
          </a>
        </p>
        <p>
          Mentors get materials on the topics of intercultural communication and
          career consulting from professional career experts and social
          psychologists.
        </p>
        <h2>About the founders:</h2>
        <p>
          Elshad Shirinov, 38, CTO, came to Germany at the age of 12 as a Jewish
          Contingent Refugee. A Soviet Jew whose father is an Aserbaidschani,
          Elshad spent his childhood in Charkiw, Ukraine, a city that is now
          almost demolished by Russian bombs.
        </p>
        <p>
          Olga Sokolova, 35, Career Consultant, came to Germany at the age of 21
          to continue her studies of Cultural History started in Saint
          Petersburg, Russia. MA in Cultural - and Religious Studies (HU, FU
          Berlin) with a focus on social psychology, she worked & volunteered a
          lot to support Russian civil society and intercultural dialogue in
          Europe. For the past 3 years she has led an international career
          consulting firm CV Abroad.
        </p>
        <h3>Tell your readers, viewers and followers about us!</h3>
        <p>
          Call a Colleague needs more visibility, more mentors, more mentees!
          You speak English and work in the EU? Register under
        </p>
        <p>
          <a href="https://call-a-colleague.eu/mentors" target="_blank">
            https://call-a-colleague.eu/mentors
          </a>{" "}
        </p>
        <p>Thank you!</p>
      </section>
    </PageLayout>
  );
};

export default AboutPage;
